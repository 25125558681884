<template>
  <div class="flex min-h-full items-end justify-center p-6 text-center sm:items-center sm:p-0" @click="$modal.close">
    <div
      class="ag-modal relative w-full transform rounded-lg text-left shadow-xl transition-all sm:my-8 sm:max-w-2xl"
      :class="[
        id,
        {
          'p-8 md:p-10': !noPadding,
          'bg-white': theme === 'light',
          'bg-gray-900': theme === 'dark',
          'sm:max-w-md': size === 'xs',
          'sm:max-w-lg': size === 'sm',
          'sm:max-w-xl': size === 'md',
          'sm:max-w-2xl': size === 'lg',
        },
      ]"
      @click.stop
    >
      <button
        v-if="!preventClose"
        type="button"
        class="modal__close absolute right-0.5 top-0.5 z-50 block h-10 w-10 justify-center rounded-md border-none bg-transparent text-sm font-medium transition-colors hover:cursor-pointer focus:outline-none sm:right-2 sm:top-2"
        :class="[theme === 'dark' ? 'text-white/50 hover:text-white' : '']"
        @click="$modal.close"
      >
        <Icon icon="times" />
      </button>
      <div class="modal__body">
        <slot name="title">
          <Heading type="h4">{{ title }}</Heading>
        </slot>
        <component
          ref="mainComponentRef"
          :is="mainComponent"
          v-bind="form?.endpoint ? { ...form, 'hide-submit': true, 'no-spacing': true } : null"
          :class="{ 'mt-5': title }"
          @success="onFormSuccess"
        >
          <slot />
        </component>
        <slot name="footer">
          <footer v-if="!hideFooter" class="mt-8 grid gap-y-3 sm:grid-cols-3 sm:gap-x-6 sm:gap-y-0">
            <Button
              v-if="!preventClose && !noCancel"
              color="gray"
              variant="ghost"
              @click="$modal.close"
              class="order-last sm:order-first"
              :ui="{ base: 'justify-center' }"
              >{{ cancelLabel }}</Button
            >
            <Button @click="onConfirm" :class="!preventClose && !noCancel ? 'sm:col-span-2' : 'sm:col-span-3'" :ui="{ base: 'justify-center' }">{{
              confirmLabel
            }}</Button>
          </footer>
        </slot>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
const props = withDefaults(
  defineProps<{
    id?: string;
    cancelLabel?: string;
    confirmLabel?: string;
    noCancel?: true;
    noPadding?: true;
    hideFooter?: true;
    preventClose?: true;
    size?: "xs" | "sm" | "md" | "lg";
    title?: string;
    theme?: "light" | "dark";
    form?: FormProps;
  }>(),
  {
    cancelLabel: "Cancel",
    confirmLabel: "Confirm",
    size: "md",
    theme: "light",
  }
);

const emit = defineEmits(["confirm"]);

const { closeModal } = useModal();

const mainComponent = props.form?.endpoint ? resolveComponent("LazyForm") : "main";

// Handle Modal confirm
const mainComponentRef = ref();
const onConfirm = () => (props.form?.endpoint ? mainComponentRef.value.submit() : emit("confirm"));
const onFormSuccess = (response) => emit("confirm", response);

// Handle Modal close
onKeyStroke("Escape", () => {
  if (!props.preventClose) {
    closeModal();
  }
});
</script>
